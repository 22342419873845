import qs from "qs";

import { Filters, QueryState, User } from "../types";

export function getQueryFromURL(s: string): QueryState {
  const parsed = qs.parse(s, { ignoreQueryPrefix: true }) as {
    filters: { [key: string]: string };
    sort?: string;
    page?: string;
  };

  const filters: Filters = {};

  for (const key in parsed.filters) {
    if (Object.prototype.hasOwnProperty.call(parsed.filters, key)) {
      const parts = key.split("_");
      const operand = parts.pop()!; // Take the last part as the operand
      const filterName = parts.join("_"); // Join the rest as the filter name
      const value = parsed.filters[key];

      if (value) {
        // Skip filters with no value
        filters[filterName] = { value: value, operand: operand };
      }
    }
  }

  return {
    filters: filters,
    sort: parsed.sort,
    page: Math.max(1, parseInt(parsed.page || "1", 10)),
  };
}

export function isTaxiFleet(accountId: string): boolean {
  const allowedAccountIds = new Set(["1", "55", "56", "57", "60"]);
  return allowedAccountIds.has(accountId);
}

export function shouldShowCobranzas(accountId: string, user: User) {
  if (user.roles && user.roles.length > 0) {
    const role = user.roles[0];
    return isTaxiFleet(accountId) && role.role_type_id === 1;
  }
  return false;
}
