import React from "react";

import { months } from "../../constants";
import { years } from "../../utils";
import FinancialReport from "./FinancialReport";

const transform = (x) => {
  const { year, groupBy, accountId } = x;
  return {
    range: [`${year}-01-01`, `${year}-12-31`],
    groupBy,
    accountId,
    tempGroup: "year",
  };
};

const title = "Cobranzas histórico anual";

const conf = { year: 2025 };

export default function AnnualReport({ match }) {
  let accountId = match.params.accountId;
  return (
    <FinancialReport
      tempGroup={"year"}
      ranges={years.map((x) => ({ label: x, value: x }))}
      tempGroupLabel={"Año: "}
      iterator={months}
      transform={transform}
      title={title}
      conf={conf}
      accountId={accountId}
    />
  );
}
