import {
  CarOutlined,
  ClockCircleOutlined,
  MedicineBoxOutlined,
  RiseOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Divider, notification } from "antd";
import Enumerable from "linq";
import { DateTime, Interval } from "luxon";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import Duration from "../components/Duration";
import { MONTH_SHORT_NAMES } from "../constants";
import { formatMoney, formatNumber } from "./format";

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export const getByKey = (collection, value, key = "id") => {
  if (!collection) return null;

  const item = collection.filter(
    (item) => item[key].toString() === value.toString()
  );
  if (item) return item[0];
  return null;
};

export const formatBrands = (brands) => {
  return Enumerable.from(brands)
    .orderByDescending((x) => x.year)
    .groupBy("$.year", null, (key, group) => ({
      value: key.toString(),
      label: key.toString(),
      children: Enumerable.from(group.getSource())
        .orderBy((x) => x.brand)
        .groupBy("$.brand", null, (key, g) => ({
          value: key,
          label: key,
          children: Enumerable.from(g.getSource())
            .orderBy((x) => x.model)
            .select((item) => ({
              value: item.model,
              label: item.model,
            }))
            .toArray(),
        }))
        .toArray(),
    }))
    .toArray();
};

export function rangeToString(range) {
  return range.map((x) => x.format("DD/MM/YYYY")).join(" - ");
}

export function calculateTimeSince(date) {
  return DateTime.fromSQL(date).toRelative({ locale: "es" });
}

export function calculateTimeSinceFromISO(date) {
  return DateTime.fromISO(date).toRelative({ locale: "es" });
}

export function formatDate(date) {
  return DateTime.fromISO(date).setLocale("es").toLocaleString({
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export const getDateTime = (date) => {
  if (date.length > 16) {
    return DateTime.fromISO(date);
  }
  return DateTime.fromSQL(date + ":00");
};

export const calculateDuration = (startDate, finishDate) => {
  let duration;

  if (startDate && startDate < finishDate) {
    const i = Interval.fromDateTimes(
      DateTime.fromISO(startDate),
      DateTime.fromISO(finishDate),
      { locale: "es" }
    );
    const { days, hours, minutes } = i.toDuration([
      "days",
      "hours",
      "minutes",
      "seconds",
    ]).values;
    duration = `${days ? days + " días " : ""} ${
      hours ? hours + " horas" : ""
    } ${minutes ? minutes + " minutos." : ""}`;
  }
  return duration;
};
const contains = (needle, haystack) => new RegExp(needle, "i").test(haystack);

export const textFilter = (text, collection, cols) =>
  collection.filter((s) => {
    for (let i = 0; i < cols.length; i++) {
      if (contains(text, s[cols[i]] || "")) {
        return true;
      }
    }
    return false;
  });
export const splitString = (needle = "", haystack = "") => {
  haystack = haystack || "";
  const re = new RegExp(needle, "i");
  const array = haystack.split(re);
  let match = haystack.match(re);
  return needle === "" || !match
    ? { 0: haystack }
    : { 0: array[0], 1: match[0], 2: array[1] };
};

export const dateFormatter = (date) => {
  let year = date.substring(2, 4);
  let month = date.substring(5, 7);

  return MONTH_SHORT_NAMES[Number(month)] + " " + year + "'";
};

export const renderTime = (date) => {
  return (
    <p>
      {formatDate(date)} <small>({calculateTimeSinceFromISO(date)})</small>
    </p>
  );
};

export const renderTimeAndMileage = (text, record) => {
  return (
    <div>
      {renderTime(record.finishDate)}
      <i>{formatNumber(record.meterEntry?.value, 0)} km.</i>
      <p>
        <Duration finishDate={record.finishDate} startDate={record.startDate} />
      </p>
    </div>
  );
};

export function odomoterUpdateHelp(vehicle) {
  let help;

  if (vehicle["meterCurrentValue"]) {
    help = `Última actualización: ${formatNumber(
      vehicle["meterCurrentValue"],
      0
    )}km (${calculateTimeSince(vehicle["meterCurrentDate"])})`;
  }
  return help;
}

export const fullName = (user) =>
  (user.firstName || "") + " " + (user.lastName || "");

export function calculateTotals(dataSource, iterator) {
  let gTot = 0;
  const totalsRow = {};
  iterator.forEach((month) => {
    let tot = 0;
    dataSource.forEach((x) => {
      tot += Number(x[month]);
    });
    totalsRow[month] = tot;
    if (month !== "Total Mes") {
      gTot += tot;
    }
  });
  totalsRow["Total"] = gTot;
  return totalsRow;
}

export const years = [
  2025,
  2024,
  2023,
  2022,
  2021,
  2020,
  2019,
  2018,
  2017,
  2016,
  2015,
];

export function nFormatter(num, digits) {
  let si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  let s = (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  return s.replace(".", ",");
}

export const groupByItems = [
  { key: "contactId", label: "Conductor", icon: <UserOutlined /> },
  { key: "vehicleId", label: "Vehículo", icon: <CarOutlined /> },
  {
    key: "both",
    label: "Vehículo y Conductor",
    icon: <MedicineBoxOutlined />,
  },
];

export function showNotification(accountId, transaction) {
  notification.success({
    message: (
      <div>
        <a
          href={`/${accountId}/vehicles/${transaction.vehicleId}/collections/${transaction.id}`}
        >
          Cobranza #{transaction.id} creada.
        </a>
        <Divider />
        <table className={"notification-table"}>
          <tbody>
            <tr>
              <th>Fecha:</th>
              <td>{transaction.date}</td>
            </tr>

            <tr>
              <th>Conductor:</th>
              <td>
                {transaction.contact.firstName} {transaction.contact.lastName}
              </td>
            </tr>
            <tr>
              <th>Vehículo:</th>
              <td>{transaction.vehicle.name}</td>
            </tr>
            <tr>
              <th>Tipo:</th>
              <td>{transaction.transactionType.name}</td>
            </tr>
            <tr>
              <th>Monto:</th>
              <td>{formatMoney(transaction.amount)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
  });
}

export const dateRanges = {
  Hoy: [moment(), moment()],
  Ayer: [moment().subtract(1, "day"), moment().subtract(1, "day")],
  "Este mes": [moment().startOf("month"), moment().endOf("month")],
  "Mes Pasado": [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
};
export const getRangeFromWeek = (week) => {
  return [
    moment().isoWeek(week).startOf("week").add(1, "day").format("YYYY-MM-DD"),
    moment().isoWeek(week).endOf("week").add(1, "day").format("YYYY-MM-DD"),
  ];
};
export const renderContact = (text, record) =>
  record.contactId ? (
    <Link to={`/${record.contact.accountId}/contacts/${record.contactId}`}>
      {record.contact.firstName + " " + record.contact.lastName}
    </Link>
  ) : (
    "-"
  );

export function calculateDurationBis(startDate, finishDate, noIcon) {
  let duration;

  const i = Interval.fromDateTimes(
    getDateTime(startDate),
    finishDate ? getDateTime(finishDate) : DateTime.local(),
    { locale: "es" }
  );

  if (!i.invalid) {
    const { days, hours, minutes } = i.toDuration([
      "days",
      "hours",
      "minutes",
      "seconds",
    ]).values;
    if (days > 0) {
      duration = ` ${days ? days + " días." : ""}  `;
    } else if (hours > 0) {
      duration = ` ${hours ? hours + " horas." : ""}  `;
    } else {
      duration = ` ${minutes ? minutes + " minutos." : ""}  `;
    }
  } else {
    duration =
      "Error: La fecha y hora de inicio no puede ser posterior a la de inicio. Por favor, corrija la fecha de inicio.";
  }

  return (
    duration && (
      <small>
        {!noIcon && <ClockCircleOutlined />} {duration}
      </small>
    )
  );
}

export const renderDateRange = (record) => {
  const formatDate = (text) =>
    DateTime.fromISO(text).setLocale("es").toLocaleString({
      year: "numeric",
      month: "short",
      day: "numeric",
    });

  return (
    formatDate(record["startDate"]) +
    " - " +
    (record["finishDate"] ? formatDate(record["finishDate"]) : "Ahora")
  );
};
export const renderDateRangeAndDuration = (text, record) => (
  <div>
    <p>{renderDateRange(record)}</p>
    <p>{calculateDurationBis(record["startDate"], record["finishDate"])}</p>
  </div>
);
export const renderDistance = (text, record) => {
  return (
    <div>
      <RiseOutlined />
      {record["startingMeterEntry"] ? (
        <span>
          {formatNumber(record["startingMeterEntry"].value, 0)}
          <small> km</small>
        </span>
      ) : (
        "Sin lectura de inicio"
      )}
      {" - "}
      {record["endingMeterEntry"] ? (
        <span>
          {formatNumber(record["endingMeterEntry"].value, 0)} <small> km</small>
        </span>
      ) : (
        "Sin lectura de finalización"
      )}
    </div>
  );
};
export const renderIgnition = (x) => {
  return x === 1 ? "Encendido" : x === 2 ? "Sin reportar" : "Apagado";
};
export const renderDate = (text) => {
  const locale = DateTime.fromISO(text).setLocale("es");
  return (
    <div>
      <p>
        {locale.toLocaleString({
          month: "short",
          hour: "2-digit",
          minute: "2-digit",
          day: "numeric",
        })}
      </p>
      <p>
        {" "}
        <small>{locale.toRelative()}</small>
      </p>
    </div>
  );
};

export function getCenter(maps, locationEntries) {
  const bound = new maps.LatLngBounds();
  for (let i = 0; i < locationEntries.length; i++) {
    bound.extend(
      new maps.LatLng(locationEntries[i].lat, locationEntries[i].lng)
    );
  }

  const boundCenter = bound.getCenter();
  return { bound, boundCenter };
}
